<template>
  <Layout>
    <div class="container">
      <div class="box">
        <div class="columns is-gapless">
          <div class="column is-one-quarter">
            <figure class="image is-128x128">
              <img class="is-rounded" :src="'/assets/images/avatar.png'" v-if="!user.avatar" />
              <i
                class="avatar is-rounded"
                v-else
                :style="{ 'background-image': `url(${user.avatar})` }"
              ></i>
              <p>Hi {{ user.name.full || user.email }}!</p>
              <div class="file" :class="disable ? 'is-danger' : ''">
                <label class="file-label">
                  <input
                    ref="file"
                    class="file-input"
                    type="file"
                    :disabled="inProgress"
                    @change="uploadAvatar()"
                  />
                  <span class="file-cta">
                    <span class="file-icon">
                      <icon :icon="['fas', 'upload']" />
                    </span>
                    <span class="file-label">Change</span>
                  </span>
                </label>
              </div>
            </figure>
          </div>
          <div class="column down">
            <div class="columns">
              <div class="column">
                <label class="label">User Information</label>
                <div>
                  <hr />
                </div>
                <p
                  v-show="error"
                  class="help is-danger"
                >There was an error while updating: {{ error }}</p>
                <div class="field is-horizontal">
                  <div class="field-label is-normal">
                    <label class="label">First Name</label>
                  </div>
                  <div class="field-body">
                    <div class="field">
                      <div class="control">
                        <input v-model="user.name.first" class="input" type="text" />
                      </div>
                    </div>
                  </div>
                </div>
                <div class="field is-horizontal">
                  <div class="field-label is-normal">
                    <label class="label">Last Name</label>
                  </div>
                  <div class="field-body">
                    <div class="field">
                      <div class="control">
                        <input v-model="user.name.last" class="input" type="text" />
                      </div>
                    </div>
                  </div>
                </div>
                <div class="field is-horizontal">
                  <div class="field-label is-normal">
                    <label class="label">Email</label>
                  </div>
                  <div class="field-body">
                    <div class="field">
                      <div class="control">
                        <input v-model="user.email" class="input" type="text" />
                      </div>
                    </div>
                  </div>
                </div>
                <div class="field is-horizontal">
                  <div class="field-label is-normal">
                    <label class="label">Company</label>
                  </div>
                  <div class="field-body">
                    <div class="field">
                      <div class="control">
                        <input disabled v-model="user.company.name" class="input" type="text" />
                      </div>
                    </div>
                  </div>
                </div>
                <div class="field is-horizontal">
                  <div class="field-label is-normal">
                    <label class="label">Role</label>
                  </div>
                  <div class="field-body">
                    <div class="field">
                      <div class="control">
                        <input disabled v-model="user.role" class="input" type="text" />
                      </div>
                    </div>
                  </div>
                </div>
                <a @click="updateUser(user)" class="button is-info" :disabled="inProgress">
                  <span class="icon">
                    <icon :icon="['fas', 'edit']" />
                  </span>
                  <span>Update Information</span>
                </a>
                <p v-show="success.show" class="help is-success">{{ success.message }}</p>
              </div>
              <div class="column">
                <label class="label">Details</label>
                <div>
                  <hr />
                </div>
                <div class="columns">
                  <div class="column entries">
                    <label class="label">Login Count</label>
                    <p>{{ user.loginCount }}</p>
                  </div>
                  <div class="column entries">
                    <label class="label">Last Login</label>
                    <p v-if="user.lastLogin">{{ user.lastLogin }}</p>
                  </div>
                  <div class="column entries">
                    <label class="label">Member Since</label>
                    <p>{{ user.createdAt }}</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </Layout>
</template>

<script>
import axios from 'axios';

export default {
  name: 'profileView',
  data() {
    return {
      inProgress: false,
      user: {
        name: {
          first: '',
          last: '',
          full: ''
        },
        company: {
          name: ''
        },
        avatar: '',
        role: '',
      },
      error: '',
      success: { message: 'The information was updated', show: false },
      disable: false
    };
  },
  components: {
    Layout: () => import('@/router/layouts/Main')
  },
  created() {
    let userId = $cookies.get($formsConfig.core.cookieName).id;
    axios.get($formsConfig.core.api.users + userId).then(response => {
      this.user = response.data;
      if (!this.user.name) {
        (this.user.name.first = ''),
          (this.user.name.last = ''),
          (this.user.name.full = '');
      }
      if (!this.user.company) {
        this.user.company = this.user.company || {};
        this.user.company.name = '';
      }
      this.user.createdAt = new Date(this.user.createdAt).toDateString();
      let lastLogin = new Date(this.user.lastLogin);
      this.user.lastLogin = `${lastLogin.toDateString()} at ${lastLogin.getHours()}:${lastLogin.getMinutes()}`;
    });
  },
  methods: {
    uploadAvatar() {
      this.disable = true;
      this.generalError = '';
      const file = this.$refs.file.files[0];
      const MAX_SIZE = 1.5e7; // 15 MB
      if (!file) {
        return (this.disable = false);
      }
      const tooLarge = file.size > MAX_SIZE;
      const allowedTypes = ['image/jpg', 'image/jpeg', 'image/png'];
      if (allowedTypes.indexOf(file.type) === -1) {
        this.$notify({
          group: 'alert',
          title: 'Unable to update avatar',
          text: 'Sorry! Unsupported file type.',
          type: 'error'
        });
        return (this.disable = false);
      }
      if (tooLarge) {
        this.$notify({
          group: 'alert',
          title: 'Unable to update avatar',
          text: 'Sorry! File too large.',
          type: 'error'
        });
        return (this.disable = false);
      }

      const formData = new FormData();
      formData.append('avatar', file);
      const options = {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      };
      this.inProgress = true;
      axios
        .post($formsConfig.core.api.avatar(this.user.id), formData, options)
        .then(response => {
          this.disable = false;
          if (response.status === 200) {
            this.error = '';
            if (response.data.company === null) {
              response.data.company = {
                name: ''
              };
            }
            this.user = response.data;
          } else {
            this.$notify({
              group: 'alert',
              title: 'Unable to update avatar',
              text: 'Sorry! We were unable to update your avatar.',
              type: 'error'
            });
          }
        })
        .catch(ex => {
          this.disable = false;
          this.$notify({
            group: 'alert',
            title: 'Unable to update avatar',
            text: ex.message || 'Sorry! We were unable to update your avatar.',
            type: 'error'
          });
        })
        .function(() => {
          this.inProgress = false;
        });
    },
    updateUser(user) {
      if (user.name.full && user.email) {
        this.inProgress = true;
        axios
          .put($formsConfig.core.api.users + user.id, { user })
          .then(response => {
            if (response.status === 200) {
              this.error = '';
              this.success.show = true;
            } else {
              this.success.show = false;
              this.error = response.data;
            }
            this.inProgress = false;
          });
      } else {
        this.success.show = false;
        this.error = 'All fields are mandatory';
      }
    }
  }
};
</script>

<style lang="scss" scoped>
$input-disabled-border-color: white;

.box {
  width: 100%;
  padding-right: 40px;
  padding-left: 40px;
  margin: 30px 0;
}

figure {
  .avatar {
    display: block;
    height: 100%;
    width: 100%;
    background-size: cover;
    background-position: 50% 50%;
    background-repeat: no-repeat;
    border-radius: 50%;
  }
  p {
    text-align: center;
  }
}

.is-one-quarter {
  width: 15% !important;
}

.is-fullwidth {
  max-width: 50%;
}

.entries {
  label,
  p {
    text-align: center;
  }
}

.file {
  > .file-label {
    margin: 0 auto 8px;
  }
}

.field-label {
  margin-right: 0;
  .label {
    text-align: left;
    font-weight: normal;
  }
}

.field-body {
  .field {
    .control {
      .input {
        width: 70%;
      }
    }
  }
}

.down {
  margin-top: 3% !important;
}
.button {
  color: $white !important;
}
</style>
